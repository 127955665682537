import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import * as mswInit from "../../mock-api/msw/mswInit.ts";
import type { ApiClient } from "./apiClient.ts";
import { UnauthenticatedError } from "./ApiError.ts";
import { currentApiKeyAtom } from "./apikey.store.ts";
import { apiPrefixAtom, createApiClient, unauthApiClientAtom, type ApiParameters } from "./createApiClient.ts";

/**
 * API client for authenticated requests (only).
 * See {@link unauthApiClientAtom} for logged-out.
 */
export const apiClientAtom = atom((get): ApiClient => {
    const params = get(currentApiParamsAtom);
    console.debug("Creating API client with params %o", params);

    async function preflightCheck() {
        if (params.apikey == null) {
            // NB: must not throw error directly inside the atom, which causes query atoms to be stuck in an error state after logout
            // (since dependent atoms get re-computed immediately when resetting the apikey value)
            throw new UnauthenticatedError();
        }

        await mswInit.waitForMswInit();
    }

    return createApiClient(params, preflightCheck);
});

export const currentApiParamsAtom = atom((get): ApiParameters => {
    return {
        apikey: get(currentApiKeyAtom),
        apiPrefix: get(apiPrefixAtom),
    };
});

export function useApiClient(): ApiClient {
    return useAtomValue(apiClientAtom);
}
