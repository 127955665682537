import type { QueryObserverResult, RefetchOptions } from "@tanstack/query-core";
import { objectExtendProxy } from "@warrenio/utils/objectExtendProxy";
import type { ValueOf } from "type-fest";
import { MultiError, type QError } from "./queryErrors.ts";

/** Unique key for a query.
 *
 * Useful for putting queries of different types into a single object, while also allowing utility functions like
 * {@link getQueriesData} to be typed properly.
 */
export type QueryId = string;

/** Status for a query.
 *
 * A subset of {@link QueryObserverResult}.
 */
export interface QStatus {
    /** Are there any queries still loading */
    isPending: boolean;
    error?: QueryObserverResult["error"];
    refetch: QError["refetch"];
}

/** The status of several merged queries */
export interface QMergedStatus extends QStatus {
    error?: MultiError;
}

/** A query result with status and data.
 * Can either be a single query or a merged query.
 *
 * A subset of {@link QueryObserverResult}.
 */
export interface QResult<T> extends QStatus {
    /** `undefined` if the query is still loading */
    data: T | undefined;
}

/** Query result from several queries merged into one */
export interface QMergedResult<T> extends QResult<T>, QMergedStatus {
    data: T;
    error?: QMergedStatus["error"];
}

/** Merge several query statuses into one.
 *
 * @param queries - The queries to merge. The keys should be arbitrary IDs to identify the queries later for refetching.
 */
export function mergeQueryStatuses(queries: Record<QueryId, QStatus>): QMergedStatus {
    const statuses = Object.values(queries);

    return {
        error: MultiError.fromStatuses(statuses),
        isPending: statuses.some((value) => value.isPending),
        async refetch(options?: RefetchOptions) {
            return await Promise.allSettled(statuses.map(async (q) => await q.refetch(options)));
        },
    };
}

/** Merge query statuses of an array of queries and for any loaded queries, combine the data using {@link mergeData} */
export function mergeQueries<
    TQueries extends Record<QueryId, QResult<TData>>,
    TData = TypeOfLoadedQResult<ValueOf<TQueries>>,
    TMerged = TData,
>(queries: TQueries, mergeData: (data: TData[]) => TMerged): QMergedResult<TMerged> {
    const allData = Object.values(queries)
        .map((q) => q.data)
        .filter((d): d is TData => d !== undefined);
    return { ...mergeQueryStatuses(queries), data: mergeData(allData) };
}

/** Get the corresponding `{ [key]: data }` from an object of `{ [key]: query }` */
export function getQueriesData<TQueries extends Record<QueryId, QResult<unknown>>>(queries: TQueries) {
    const result: Record<QueryId, unknown> = {};
    for (const [key, query] of Object.entries(queries)) {
        result[key] = query.data;
    }
    return result as { [K in keyof TQueries]: TQueries[K]["data"] };
}

/** Execute a function on the data of several queries, once they are all loaded */
export function mergeLoadedQueries<TQueries extends Record<QueryId, QResult<unknown>>, TResult>(
    queries: TQueries,
    fn: (data: { [K in keyof TQueries]: TypeOfLoadedQResult<TQueries[K]> }) => TResult,
): QMergedResult<TResult | undefined> {
    const allData = getQueriesData(queries);
    const allLoaded = Object.values(allData).every((data) => data !== undefined);
    return {
        ...mergeQueryStatuses(queries),
        // Cast is safe due to the `allLoaded` check
        data: allLoaded ? fn(allData as Parameters<typeof fn>[0]) : undefined,
    };
}

/** Get the data type of a {@link QResult} */
export type TypeOfQResult<TStatus extends QResult<unknown>> = TStatus extends QResult<infer T> ? T : never;

export type TypeOfLoadedQResult<TStatus extends QResult<unknown>> = Exclude<TypeOfQResult<TStatus>, undefined>;

/**
 * Change the data type of a {@link QResult}.
 * (to make the return type of {@link mapQueryData} more readable)
 */
type ChangeQResultType<TQuery extends QResult<unknown>, TData> =
    TQuery extends QResult<infer _>
        ? QResult<TData>
        : TQuery extends QMergedResult<infer _>
          ? QMergedResult<TData>
          : never;

/** Apply a function to the data of a query while keeping the status the same. */
export function mapQueryData<TQuery extends QResult<TData>, TResult, TData = TypeOfLoadedQResult<TQuery>>(
    query: TQuery,
    fn: (data: TData) => TResult,
) {
    const { data } = query;
    // Use a proxy instead of splatting to prevent unnecessary React Query property tracking (and re-renders).
    return objectExtendProxy(query, {
        data: data !== undefined ? fn(data) : undefined,
    }) as QResult<unknown> as ChangeQResultType<TQuery, TResult>;
}

/** Apply a function to the data of several queries while merging the statuses. */
export function mapQueriesData<TQueries extends Record<QueryId, QResult<unknown>>, TResult>(
    queries: TQueries,
    fn: (data: { [K in keyof TQueries]: TQueries[K]["data"] }) => TResult,
) {
    const allData = getQueriesData(queries);
    return { ...mergeQueryStatuses(queries), data: fn(allData) };
}
