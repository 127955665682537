import MLC from "../modules/main/MainLayout.module.css";
import TNC from "../modules/main/TopNav.module.css";
import C from "./AdminLayout.module.css";

import { Link, type LinkProps } from "@tanstack/react-router";
import { useAtomValue } from "jotai/react";
import React, { Suspense, useState, type ReactNode } from "react";
import { WButton } from "../components/button/WButton.tsx";
import { MaskIcon } from "../components/icon/MaskIcon.tsx";
import { IsolatedChildren } from "../components/Isolated.tsx";
import { Spacer } from "../components/Spacer.tsx";
import { LayoutContent } from "../modules/main/LayoutContent.tsx";
import { showOnlyContentAtom } from "../modules/main/MainLayout.store.ts";
import { mcn, type BaseProps } from "../utils/baseProps.ts";
import { cn } from "../utils/classNames.ts";

const hasDebugBar = !import.meta.env.PROD;

const ColorSchemeMenu = React.lazy(() => import("../modules/main/ColorSchemeMenu.tsx"));
const NotificationMenu = React.lazy(() => import("../modules/notifications/NotificationMenu.tsx"));
const UserMenu = React.lazy(() => import("../modules/user/UserMenu.tsx"));
const DebugButton = React.lazy(() => import("../modules/main/debug/DebugButton.tsx"));
const DebugBar = React.lazy(() => import("../modules/main/debug/DebugMenu.tsx"));

export function AdminLayout({ children }: { children: ReactNode }) {
    const showOnlyContent = useAtomValue(showOnlyContentAtom);
    if (showOnlyContent) {
        return children;
    }

    return (
        <div className="size-full flex flex-col">
            <AdminTopNav />
            <div className={cn(MLC.mainArea)}>
                <AdminSideNav />
                <LayoutContent className={cn("flex-grow", MLC.Scrollable, MLC.strict)}>{children}</LayoutContent>
            </div>
        </div>
    );
}

export function AdminTopNav(props: BaseProps) {
    const header = (
        <header {...mcn(cn("HStack", TNC.TopNav), props)}>
            <Logo />
            <Spacer />
            <IsolatedChildren>
                <NotificationMenu />
                <ColorSchemeMenu />
                {hasDebugBar && <DebugButton />}
                <UserMenu />
            </IsolatedChildren>
        </header>
    );

    return (
        <>
            {header}
            {hasDebugBar && (
                <Suspense>
                    <DebugBar />
                </Suspense>
            )}
        </>
    );
}

function Logo() {
    return <Link to="/admin" aria-label="Home" className={`bg-icon ${TNC.logo} mr-2`} />;
}

function NavLink({ icon, children, ...props }: LinkProps & BaseProps & { icon: string; children: ReactNode }) {
    const commonIconClassName = "color-primary size-0.875rem";
    return (
        <Link activeOptions={{ exact: true }} {...mcn(C.SideNavButton, props)}>
            <MaskIcon className={`${icon} ${commonIconClassName}`} />
            <span className={C.Label}>{children}</span>
        </Link>
    );
}

interface MenuItem {
    id: string;
    title: string;
    icon: string;
    to?: LinkProps["to"];
    childItems?: MenuItem[];
}

function AdminSideNav(props: BaseProps) {
    const [isCompact, setIsCompact] = useState(false);
    const [isOpen, setIsOpen] = useState<string[]>([]);

    const menuItems: MenuItem[] = [
        {
            id: "dashboard",
            title: "Dashboard",
            icon: "jp-view-apps-icon",
            to: "/admin",
        },
        {
            id: "users",
            title: "Users",
            icon: "jp-user-group-icon",
            to: "/admin/users",
        },
        {
            id: "compute",
            title: "Compute",
            icon: "jp-compute-cloud-icon",
            childItems: [
                {
                    id: "baremetal",
                    title: "Baremetal",
                    icon: "jp-host-icon",
                    to: "/admin/baremetal",
                },
                {
                    id: "baremetalpools",
                    title: "Baremetal Pools",
                    icon: "jp-hostpool-icon",
                    to: "/admin/baremetalpools",
                },
            ],
        },
        {
            id: "accounts",
            title: "Billing",
            icon: "jp-card-icon",
            to: "/admin/accounts",
        },
        {
            id: "reports",
            title: "Reports",
            icon: "jp-icon-text-editor",
            to: "/admin/reports",
        },
        {
            id: "marketing",
            title: "Marketing",
            icon: "jp-broadcast-icon",
            childItems: [
                {
                    id: "campaigns",
                    title: "Campaigns",
                    icon: "jp-icon-ellipses",
                    to: "/admin/campaigns",
                },
                {
                    id: "referral",
                    title: "Referral Program",
                    icon: "jp-icon-ellipses",
                    to: "/admin/referral",
                },
                {
                    id: "tracking",
                    title: "Tracking Codes",
                    icon: "jp-icon-ellipses",
                    to: "/admin/tracking",
                },
            ],
        },
        /*{
            id: "prices",
            title: "Prices",
            icon: "i-lucide:banknote",
            to: "/admin/prices",
        },*/
    ];

    const toggleOpen = (value: string) => {
        const found = isOpen.find((item: string) => item === value);
        if (found) {
            setIsOpen(isOpen.filter((item: string) => item !== value));
        } else {
            setIsOpen([...isOpen, value]);
        }
    };

    const hasOpen = (value: string) => {
        const found = isOpen.find((item: string) => item === value);
        return !!found;
    };

    return (
        <div {...mcn(cn(C.SideNav, isCompact && C.Compact), props)}>
            {menuItems.map((item) => {
                const { title, icon, to, id } = item;
                const children = item.childItems;
                return children?.length ? (
                    <div key={id} className={cn(C.SideNavHolder, hasOpen(id) && C.isOpen)}>
                        <div
                            className={cn(C.SideNavHeader, hasOpen(id) && "color-primary")}
                            onClick={() => toggleOpen(id)}
                        >
                            <MaskIcon className={cn(icon, "color-primary size-0.875rem")} />
                            <span className={C.Label}>{title}</span>
                            <MaskIcon
                                className={cn(
                                    hasOpen(id) ? "i-lucide:chevron-up" : "i-lucide:chevron-down",
                                    isCompact ? C.Icon : "size-0.875rem",
                                    "color-primary",
                                )}
                            />
                        </div>

                        <div style={hasOpen(id) ? {} : { visibility: "hidden", height: "0" }}>
                            {children.map((child) => {
                                const { title, icon, to, id } = child;
                                return (
                                    <NavLink key={id} to={to} icon={icon}>
                                        {title}
                                    </NavLink>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <NavLink key={id} to={to} icon={icon}>
                        {title}
                    </NavLink>
                );
            })}

            <div className={C.Footer}>
                <WButton
                    color="primary"
                    size="bar"
                    variant="ghost"
                    icon={isCompact ? "i-lucide:chevron-right" : "i-lucide:chevron-left"}
                    action={() => setIsCompact(!isCompact)}
                />
            </div>
        </div>
    );
}
