import { queryOptions, useMutation, type QueryKey, type UseQueryOptions } from "@tanstack/react-query";
import { useRouter, type RegisteredRouter } from "@tanstack/react-router";
import type { AccessImpersonation } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { useStore } from "jotai/react";
import type { createStore } from "jotai/vanilla";
import { mutationOptions } from "../../../utils/query/runMutation.ts";
import { apiClientAtom } from "../../api/apiClient.store.ts";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import { raiseRequestToast, type Toast } from "../../notifications/toast.tsx";
import { impersonationAtom } from "../../user/impersonation.ts";

//#region basic Types/Contracts

export type Response = Map<string, AccessImpersonationWithType>;

export interface AccessImpersonationWithType extends AccessImpersonation {
    $type: "access_impersonation";
}

export const emptyResponse: Response = new Map();

export const queryKey: QueryKey = ["/user-resource/impersonation"];

//#endregion

//#region Queries

const toastOptions: Partial<Toast> = { icon: "jp-user-icon" };

export function accessImpersonationFromData(data: AccessImpersonation): AccessImpersonationWithType {
    return { ...data, $type: "access_impersonation" };
}

export function accessImpersonationListQuery(apiClient: ApiClient): UseQueryOptions<Response> {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            const response = getResponseData(await apiClient.GET("/user-resource/impersonation", { signal }));

            return mapFromEntries(response, (o) => [o.uuid, accessImpersonationFromData(o)]);
        },
    });
}

export function startImpersonationMutation(store: ReturnType<typeof createStore>, router: RegisteredRouter) {
    const apiClient = store.get(apiClientAtom);

    return mutationOptions({
        mutationKey: ["start_impersonation"],
        async mutationFn({ uuid }: AccessImpersonation) {
            return getResponseData(
                await apiClient.POST("/user-resource/impersonation/{uuid}/start", {
                    params: { path: { uuid } },
                }),
            );
        },
        async onSuccess({ token }, { access_owner }) {
            store.set(impersonationAtom, { token, access_owner });
            await router.navigate({ to: "/" });
        },

        // Only raise toast on error
        onError(err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Impersonation started",
                error: "Failed to start impersonation",
            });
        },
    });
}

export function useStartImpersonation() {
    const store = useStore();
    const router = useRouter();
    return useMutation(startImpersonationMutation(store, router));
}

//#endregion
