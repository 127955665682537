import { type QueryClient, type QueryKey, queryOptions, type UseQueryOptions } from "@tanstack/react-query";
import type {
    IpAddress,
    IpAddressAssignBody,
    IpAddressCreateBody,
    IpAddressModifyBody,
} from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { optimisticPromise } from "../../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../../utils/query/produceQueryData.ts";
import { atomFromStandardQueryOptions } from "../../../utils/query/queryAtom.ts";
import { mutationOptions, runMutation } from "../../../utils/query/runMutation.ts";
import { type ApiClient, getResponseData } from "../../api/apiClient.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { atomAllLocationsQuery } from "../../location/atomAllLocationsQuery.ts";
import {
    raiseBackgroundErrorToast,
    raiseRequestToast,
    type RequestToastOptions,
    type Toast,
} from "../../notifications/toast.tsx";
import { ipAddressLink } from "../links.ts";

//#region basic Types/Contracts
export interface Params {
    location: string;
}

export interface IpAddressWithType extends IpAddress, Params {
    $type: "ip_address";
}

export type Response = Map<string, IpAddressWithType>;

const emptyResponse: Response = new Map();

const toastOptions: Partial<Toast> = { icon: getResourceIconClass("ip_address") };

//#endregion basic Types/Contracts

//#region helper functions

// NB: Need explicit return type to avoid TypeScript error for `UseQueryOptions` below.
export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["ip_address/list"] : ["ip_address/list", params.location];
}

function ipFromData(data: IpAddress, location: string): IpAddressWithType {
    return { ...data, $type: "ip_address", location };
}

//#endregion helper functions

//#region Queries

export function getSingleQuery(apiClient: ApiClient, params: Params): UseQueryOptions<Response> {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/network/ip_addresses", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return mapFromEntries(response, (ip) => [ip.uuid, ipFromData(ip, location)]);
        },
    });
}

export const ipQueryAtom = atomFamily((location: string) => atomFromStandardQueryOptions(getSingleQuery, { location }));

export const allIpQueryAtom = atomAllLocationsQuery(ipQueryAtom, (results) => mergeMaps(results));

//#endregion Queries

//#region Mutations

export function createFullIpAddressMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        async mutationFn({
            body,
            location,
            private_ip = undefined,
        }: {
            body: IpAddressCreateBody;
            location: string;
            private_ip?: string | undefined;
        }) {
            const result = await runMutation(queryClient, ipAddressCreateMutation(api, queryClient), {
                body,
                location,
            });

            let assignResult = null;
            if (private_ip !== undefined) {
                try {
                    assignResult = await runMutation(
                        queryClient,
                        assignIpAddressMutation(api, queryClient, { silent: true }),
                        {
                            body: { private_ip },
                            location,
                            address: result.address,
                        },
                    );
                } catch (e) {
                    raiseBackgroundErrorToast(e, "IP assign failed");
                }
            }

            return {
                result: ipFromData(result, location),
                assignResult: assignResult && ipFromData(assignResult, location),
            };
        },
    });
}

export function ipAddressCreateMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["ip_address/create"],
        async mutationFn({ body, location }: { body: IpAddressCreateBody; location: string }) {
            const result = getResponseData(
                await api.POST("/{location}/network/ip_addresses", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
            return ipFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "IP Address created",
                error: "Error creating IP Address",
                successType: "success",
                viewLink: res ? ipAddressLink(res) : undefined,
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function modifyIpAddressMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["ip_address/modify"],
        async mutationFn({
            body,
            location,
            address,
        }: {
            body: IpAddressModifyBody;
            location: string;
            address: string;
        }) {
            const result = getResponseData(
                await api.PATCH("/{location}/network/ip_addresses/{address}", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location, address } },
                }),
            );
            return ipFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "IP Address updated",
                error: "Error updating IP Address",
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function assignIpAddressMutation(
    api: ApiClient,
    queryClient: QueryClient,
    mutationToastOptions: Partial<RequestToastOptions> = {},
) {
    return mutationOptions({
        mutationKey: ["ip_address/assign"],
        async mutationFn({
            body,
            location,
            address,
        }: {
            body: IpAddressAssignBody;
            location: string;
            address: string;
        }) {
            const result = getResponseData(
                await api.POST("/{location}/network/ip_addresses/{address}/assign", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location, address } },
                }),
            );
            return ipFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "IP Address assigned to resource",
                error: "Error assigning IP Address",
                ...mutationToastOptions,
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function unassignIpAddressMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["ip_address/unassign"],
        async mutationFn({ location, address }: { location: string; address: string }) {
            const result = getResponseData(
                await api.POST("/{location}/network/ip_addresses/{address}/unassign", {
                    params: { path: { location, address } },
                }),
            );
            return ipFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "IP Address unassigned",
                error: "Error unassigning IP Address",
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function deleteIpAddressMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["ip_address/delete"],
        async mutationFn({ location, address }: { location: string; address: string }) {
            return getResponseData(
                await api.DELETE("/{location}/network/ip_addresses/{address}", {
                    params: { path: { location, address } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "IP Address deleted",
                error: "Error deleting IP Address",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

//#endregion Mutations
