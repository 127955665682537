import { SECONDS } from "@warrenio/utils/timeUnits";
import { useStore } from "jotai/react";
import { atom } from "jotai/vanilla";
import { useEffect, useRef } from "react";
import { cleanTimeout } from "../../utils/cleanTimeout.ts";
import { allAssignedVmsQueryAtom } from "../compute/joinAssignedQuery.ts";
import { networkListQueryAtom } from "../network/networkListQuery.ts";
import { storageListQueryWithPricesAtom } from "../storage/storageListQuery.ts";

/** Prefetch query result atoms used by sidebar sections (for faster navigation) */
const prefetchAtom = atom((get) => {
    try {
        get(storageListQueryWithPricesAtom);
        get(allAssignedVmsQueryAtom);
        get(networkListQueryAtom);
    } catch (e) {
        console.warn("Error in prefetch", e);
    }
});

const PREFETCH_START_DELAY = 1.5 * SECONDS;
const PREFETCH_MOUNT_DURATION = 15 * SECONDS;

export function QueryPrefetch() {
    const store = useStore();

    const unsubscribeRef = useRef<() => void>();
    const cleanTimeoutRef = useRef<() => void>();

    useEffect(() => {
        cleanTimeoutRef.current = cleanTimeout(() => {
            console.debug("Prefetching...");

            // Subscribing to the atom causes the queries to be triggered
            unsubscribeRef.current = store.sub(prefetchAtom, () => {});

            // Unsubscribe from the prefetch atom eventually to prevent unnecessary query auto-refresh later
            // (all mounted queries are periodically refreshed)
            cleanTimeoutRef.current = cleanTimeout(() => {
                unsubscribeRef.current?.();
                unsubscribeRef.current = undefined;

                cleanTimeoutRef.current = undefined;
            }, PREFETCH_MOUNT_DURATION);
        }, PREFETCH_START_DELAY);

        return () => {
            cleanTimeoutRef.current?.();
            unsubscribeRef.current?.();
        };
    }, [store]);

    return null;
}

export default QueryPrefetch;
