import { atom } from "jotai/vanilla";
import { mergeQueries } from "../../utils/query/mergeQueries.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import * as delegationOps from "./delegation/apiOperations.ts";
import * as tokenOps from "./token/apiOperations.ts";

export function useAccessList() {
    return useSuspenseQueryAtom(accessListQueryAtom);
}

export const accessListQueryAtom = atom((get) => {
    const apiTokenQ = get(tokenOps.apiTokenListQueryAtom);
    const accessDelegationQ = get(delegationOps.accessDelegationListQueryAtom);
    return mergeQueries({ apiTokenQ, accessDelegationQ }, (maps) => maps.flatMap((m) => [...m.values()]));
});
